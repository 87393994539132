import React from 'react'
import Hero from '../components/Hero'
import Team from '../components/Team'
import News from '../components/News'
const Main = () => {
  return (
    <div>
    <Hero  />
     <Team  />
     <News />
    </div>
  )
}

export default Main